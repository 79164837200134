import * as React from "react";
import { useImperativeHandle } from "react";
import {
  FArticleInterface,
  PriceDtoInterface,
} from "../../../interfaces/FArticleInterface";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import {
  Card,
  CardContent,
  Grid,
  Switch,
  TableCell,
  TextField,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { TARIF_PUBLIC } from "../../../utils/UserUtils";
import { priceFormat } from "../../../utils/FormatUtils";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import { InputInterface } from "../../../interfaces/InputInterface";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import { PCattarifInterface } from "../../../interfaces/UserInterface";
import { format2Decimal } from "../../../helpers/PaymentHelper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface State {
  fArticle: FArticleInterface | undefined;
  edit: boolean;
  setFArticle: Function;
  createFrom?: FArticleInterface;
}

interface State2 {
  fArticle: FArticleInterface;
}

interface State3 {
  fArticle: FArticleInterface;
  pCattarif: PCattarifInterface;
  globalPrice: FormState;
  coefOrRemise: boolean;
}

interface FormState {
  coefOrRemise: InputInterface;
  arPrixach: InputInterface;
  percentageOccasion: InputInterface;
  arCoef: InputInterface;
  arPrixven: InputInterface;
}

interface FormState2 {
  acCoef: InputInterface;
  acRemise: InputInterface;
}

export interface PCattarifRefInterface {
  ref: any;
  pCattarif: PCattarifInterface;
}

const FArticlePricePcattarifComponent = React.memo(
  React.forwardRef(
    ({ fArticle, pCattarif, globalPrice, coefOrRemise }: State3, ref) => {
      const { t } = useTranslation();

      const priceDto: PriceDtoInterface =
        // @ts-ignore
        fArticle["priceDto" + pCattarif.cbindice];

      const getDefaultValues = React.useCallback((): FormState2 => {
        let acCoef = Number(priceDto.fArtclient?.acCoef ?? 0);
        if (globalPrice && acCoef === 0) {
          acCoef = Number(globalPrice.arCoef.value);
        }
        let acRemise = Number(priceDto.fArtclient?.acRemise ?? 0);
        return {
          acCoef: { value: acCoef, error: "" },
          acRemise: { value: acRemise, error: "" },
        };
      }, [
        globalPrice,
        priceDto.fArtclient?.acCoef,
        priceDto.fArtclient?.acRemise,
      ]);
      const [values, setValues] = React.useState<FormState2>(
        getDefaultValues()
      );

      const handleChange = React.useCallback(
        (prop: keyof FormState2) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                [prop]: { ...v[prop], value: event.target.value, error: "" },
              };
            });
          },
        []
      );

      const onBlur = React.useCallback(() => {
        if (values.acCoef.value === "" || values.acRemise.value === "") {
          setValues((v) => {
            let acCoef = v.acCoef.value;
            if (acCoef === "") {
              acCoef = Number(priceDto.fArtclient?.acCoef ?? 0);
              if (globalPrice && acCoef === 0) {
                acCoef = Number(globalPrice.arCoef.value);
              }
            }
            let acRemise = v.acRemise.value;
            if (acRemise === "") {
              acRemise = Number(priceDto.fArtclient?.acRemise ?? 0);
            }
            return {
              ...v,
              acCoef: {
                ...v.acCoef,
                value: acCoef,
                error: "",
              },
              acRemise: {
                ...v.acRemise,
                value: acRemise,
                error: "",
              },
            };
          });
        }
      }, [
        globalPrice,
        priceDto.fArtclient?.acCoef,
        priceDto.fArtclient?.acRemise,
        values.acCoef.value,
        values.acRemise.value,
      ]);

      const getValue = React.useCallback(() => {
        return {
          catTarifCtIntitule: pCattarif.ctIntitule,
          acCoef: (!coefOrRemise
            ? values.acCoef.value
            : globalPrice.arCoef.value
          ).toString(),
          acRemise: (coefOrRemise ? values.acRemise.value : 0).toString(),
        };
      }, [
        coefOrRemise,
        globalPrice.arCoef.value,
        pCattarif.ctIntitule,
        values.acCoef.value,
        values.acRemise.value,
      ]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        setValues(getDefaultValues());
      }, [
        globalPrice.arCoef.value,
        priceDto.fArtclient?.acCoef,
        priceDto.fArtclient?.acRemise,
      ]);

      let acPrixven = Number(priceDto.fArtclient?.acPrixven ?? 0);
      if (globalPrice && acPrixven === 0) {
        acPrixven =
          (!coefOrRemise ? values.acCoef.value : globalPrice.arCoef.value) *
          globalPrice.arPrixach.value;
      }
      acPrixven =
        (1 - (coefOrRemise ? values.acRemise.value : 0) / 100) * acPrixven;

      return (
        <Grid container spacing={1} sx={{ marginTop: 1, alignItems: "center" }}>
          <Grid item xs={3}>
            {pCattarif.ctIntitule}
          </Grid>
          <Grid item xs={3}>
            <TextField
              autoComplete="off"
              disabled={coefOrRemise}
              onBlur={onBlur}
              error={!!values.acCoef.error}
              helperText={t(values.acCoef.error ?? "")}
              sx={{ width: "100%" }}
              required
              type="number"
              value={!coefOrRemise ? values.acCoef.value : 0}
              onChange={handleChange("acCoef")}
              label={t("field.acCoef")}
            />
          </Grid>
          <Grid item xs={3}>
            {format2Decimal(acPrixven).toFixed(2)}
          </Grid>
          <Grid item xs={3}>
            <TextField
              autoComplete="off"
              disabled={!coefOrRemise}
              onBlur={onBlur}
              error={!!values.acRemise.error}
              helperText={t(values.acRemise.error ?? "")}
              sx={{ width: "100%" }}
              required
              type="number"
              value={coefOrRemise ? values.acRemise.value : 0}
              onChange={handleChange("acRemise")}
              label={t("field.acRemise")}
            />
          </Grid>
        </Grid>
      );
    }
  )
);

const FArticlePricesDisplayComponent: React.FC<State2> = React.memo(
  ({ fArticle }) => {
    const { t, i18n } = useTranslation();
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {isAdmin && (
              <StyledTableRow>
                <StyledTableCell>{t("word.prices.arPrixach")}</StyledTableCell>
                <StyledTableCell>
                  {priceFormat(
                    Number(fArticle.arPrixach) * 100,
                    i18n.language,
                    "EUR"
                  )}
                </StyledTableCell>
              </StyledTableRow>
            )}
            <StyledTableRow>
              <StyledTableCell>{t("word.prices.publicHt")}</StyledTableCell>
              <StyledTableCell sx={{ textDecoration: "line-through" }}>
                {priceFormat(
                  // @ts-ignore
                  fArticle["priceDto" + TARIF_PUBLIC].priceHt,
                  i18n.language,
                  "EUR"
                )}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>{t("word.prices.discount")}</StyledTableCell>
              <StyledTableCell>
                {priceFormat(
                  // @ts-ignore
                  fArticle["priceDto" + TARIF_PUBLIC].priceHt -
                    fArticle.priceDto.priceHt,
                  i18n.language,
                  "EUR"
                )}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>{t("word.prices.yourHt")}</StyledTableCell>
              <StyledTableCell>
                {priceFormat(fArticle.priceDto.priceHt, i18n.language, "EUR")}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>{fArticle.priceDto.tvaIntitule}</StyledTableCell>
              <StyledTableCell>
                {priceFormat(
                  fArticle.priceDto.priceTtc - fArticle.priceDto.priceHt,
                  i18n.language,
                  "EUR"
                )}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>{t("word.prices.yourTTC")}</StyledTableCell>
              <StyledTableCell>
                {priceFormat(fArticle.priceDto.priceTtc, i18n.language, "EUR")}
              </StyledTableCell>
            </StyledTableRow>
            {isAdmin && (
              <StyledTableRow>
                <StyledTableCell>
                  {t("field.percentageOccasion")}
                </StyledTableCell>
                <StyledTableCell>
                  {fArticle.fArticleProp?.percentageOccasion}%
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
);

const FArticlePricesComponent = React.memo(
  React.forwardRef(
    ({ fArticle, edit, setFArticle, createFrom }: State, ref) => {
      const { t } = useTranslation();
      const [thisEdit, setThisEdit] = React.useState(
        edit !== undefined ? edit : false
      );
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const pCattarifs = useAppSelector(
        (state: RootState) => state.globalState.pCattarifs
      );
      const getDefaultValues = React.useCallback((): FormState => {
        let coefOrRemise = !!createFrom;
        if (!coefOrRemise && pCattarifs && fArticle) {
          let listCoef = [];
          for (const pCattarif of pCattarifs) {
            const priceDto: PriceDtoInterface =
              // @ts-ignore
              fArticle["priceDto" + pCattarif.cbindice];
            listCoef.push(priceDto?.fArtclient?.acCoef);
          }
          // @ts-ignore
          listCoef = [...new Set(listCoef)].filter((x) => Number(x) > 0);
          coefOrRemise = listCoef.length <= 1;
        }
        return {
          coefOrRemise: {
            value: coefOrRemise,
            error: "",
          },
          arPrixach: {
            value: Number(
              createFrom?.arPrixach ?? fArticle?.arPrixach ?? 0
            ).toString(),
            error: "",
          },
          percentageOccasion: {
            value: Number(
              createFrom?.fArticleProp?.percentageOccasion ??
                fArticle?.fArticleProp?.percentageOccasion ??
                10
            ).toString(),
            error: "",
          },
          arCoef: {
            value: Number(
              createFrom?.arCoef ?? fArticle?.arCoef ?? 0
            ).toString(),
            error: "",
          },
          arPrixven: {
            value: Number(
              createFrom?.arPrixven ?? fArticle?.arPrixven ?? 0
            ).toString(),
            error: "",
          },
        };
      }, [createFrom, fArticle, pCattarifs]);
      const getThisPCattarifs =
        React.useCallback((): PCattarifRefInterface[] => {
          return (
            pCattarifs?.map((pCattarif) => {
              return {
                ref: React.createRef(),
                pCattarif: pCattarif,
              };
            }) ?? []
          );
        }, [pCattarifs]);
      const [pCattarifsRef, setPCattarifsRef] = React.useState<
        PCattarifRefInterface[]
      >(getThisPCattarifs());
      const [loading, setLoading] = React.useState(false);
      const [values, setValues] = React.useState<FormState>(getDefaultValues());
      const handleThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );

      const getValue = React.useCallback(() => {
        return {
          arPrixach: values.arPrixach.value.toString(),
          arCoef: values.arCoef.value.toString(),
          arPrixven: values.arPrixven.value.toString(),
          fArtclients: pCattarifsRef.map((pCattarifRef) => {
            return pCattarifRef.ref.current.getValue();
          }),
          fArticleProp: {
            percentageOccasion: Number(values.percentageOccasion.value),
          },
        };
      }, [
        pCattarifsRef,
        values.arCoef.value,
        values.arPrixach.value,
        values.arPrixven.value,
        values.percentageOccasion.value,
      ]);

      const save = React.useCallback(async () => {
        setLoading(true);
        const fArticleApi = new FormData();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            ...getValue(),
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          setFArticle(response.content);
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [fArticle?.arRef, getValue, setFArticle, t, token]);

      const handleChange = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                [prop]: { ...v[prop], value: event.target.value, error: "" },
              };
            });
          },
        []
      );

      const onBlur = React.useCallback(() => {
        if (
          values.arPrixach.value.trim() === "" &&
          values.arCoef.value.trim() !== "" &&
          values.arPrixven.value.trim() !== ""
        ) {
          setValues((v) => {
            return {
              ...v,
              arPrixach: {
                ...v.arPrixach,
                value: (
                  Math.round(
                    (Number(values.arPrixven.value) /
                      Number(values.arCoef.value)) *
                      100
                  ) / 100
                ).toString(),
                error: "",
              },
            };
          });
        } else if (
          values.arPrixach.value.trim() !== "" &&
          values.arCoef.value.trim() === "" &&
          values.arPrixven.value.trim() !== ""
        ) {
          setValues((v) => {
            return {
              ...v,
              arCoef: {
                ...v.arCoef,
                value: (
                  Math.round(
                    (Number(values.arPrixven.value) /
                      Number(values.arPrixach.value)) *
                      100
                  ) / 100
                ).toString(),
                error: "",
              },
            };
          });
        } else if (
          values.arPrixach.value.trim() !== "" &&
          values.arCoef.value.trim() !== "" &&
          values.arPrixven.value.trim() === ""
        ) {
          setValues((v) => {
            return {
              ...v,
              arPrixven: {
                ...v.arPrixven,
                value: (
                  Math.round(
                    Number(values.arPrixach.value) *
                      Number(values.arCoef.value) *
                      100
                  ) / 100
                ).toString(),
                error: "",
              },
            };
          });
        }
      }, [values.arCoef.value, values.arPrixach.value, values.arPrixven.value]);

      const handleChangeSwitch = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                [prop]: {
                  ...v[prop],
                  value: event.target.checked,
                  error: "",
                },
              };
            });
          },
        []
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
        changeArPrixach(arPrixach: number) {
          setValues((v) => {
            v.arPrixach.value = arPrixach.toString();
            v.arPrixach.error = "";
            v.arPrixven.value = "";
            return { ...v };
          });
          setTimeout(() => onBlur());
        },
      }));

      React.useEffect(() => {
        if (edit !== undefined) {
          setThisEdit(edit);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setPCattarifsRef(getThisPCattarifs());
      }, [pCattarifs]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues(getDefaultValues());
      }, [fArticle, thisEdit]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {/*@ts-ignore*/}
          {((fArticle && fArticle["priceDto" + TARIF_PUBLIC]) ||
            createFrom) && (
            <>
              {isAdmin && (thisEdit || edit) ? (
                <Card variant="outlined">
                  <CardContent>
                    <Grid container spacing={1} sx={{ marginTop: 1 }}>
                      <Grid item xs={12}>
                        <TextField
                          autoComplete="off"
                          onBlur={onBlur}
                          error={!!values.arPrixach.error}
                          helperText={t(values.arPrixach.error ?? "")}
                          sx={{ width: "100%" }}
                          required
                          type="number"
                          value={values.arPrixach.value}
                          onChange={handleChange("arPrixach")}
                          label={t("field.arPrixach")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          autoComplete="off"
                          onBlur={onBlur}
                          error={!!values.arCoef.error}
                          helperText={t(values.arCoef.error ?? "")}
                          sx={{ width: "100%" }}
                          required
                          type="number"
                          value={values.arCoef.value}
                          onChange={handleChange("arCoef")}
                          label={t("field.arCoef")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          autoComplete="off"
                          onBlur={onBlur}
                          error={!!values.arPrixven.error}
                          helperText={t(values.arPrixven.error ?? "")}
                          sx={{ width: "100%" }}
                          required
                          type="number"
                          value={values.arPrixven.value}
                          onChange={handleChange("arPrixven")}
                          label={t("field.arPrixven")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography>{t("field.acCoef")}</Typography>
                          <Switch
                            checked={values.coefOrRemise.value}
                            onChange={handleChangeSwitch("coefOrRemise")}
                          />
                          <Typography>{t("field.acRemise")}</Typography>
                        </Box>
                      </Grid>
                      {pCattarifsRef?.map((pCattarifRef, indexPCattarifRef) => {
                        if (
                          (createFrom ?? fArticle)?.hasOwnProperty(
                            "priceDto" + pCattarifRef.pCattarif.cbindice
                          )
                        ) {
                          return (
                            <Grid item xs={12} key={indexPCattarifRef}>
                              <FArticlePricePcattarifComponent
                                // @ts-ignore
                                fArticle={createFrom ?? fArticle}
                                pCattarif={pCattarifRef.pCattarif}
                                coefOrRemise={values.coefOrRemise.value}
                                globalPrice={values}
                                ref={pCattarifRef.ref}
                              />
                            </Grid>
                          );
                        }
                        return <React.Fragment key={indexPCattarifRef} />;
                      })}
                      <Grid item xs={12}>
                        <TextField
                          autoComplete="off"
                          onBlur={onBlur}
                          error={!!values.percentageOccasion.error}
                          helperText={t(values.percentageOccasion.error ?? "")}
                          sx={{ width: "100%" }}
                          required
                          type="number"
                          value={values.percentageOccasion.value}
                          onChange={handleChange("percentageOccasion")}
                          label={t("field.percentageOccasion")}
                        />
                      </Grid>
                    </Grid>
                    {!edit && (
                      <Box
                        sx={{ display: "flex", justifyContent: "space-evenly" }}
                      >
                        <Tooltip title={t("word.cancel")}>
                          <IconButton
                            onClick={handleThisEdit}
                            disabled={loading}
                          >
                            <CancelIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("word.save")}>
                          <LoadingButton
                            variant="text"
                            color="inherit"
                            sx={{
                              borderRadius: "50%",
                              minWidth: "auto",
                              padding: "8px",
                              color: "rgba(0, 0, 0, 0.54)",
                            }}
                            loading={loading}
                            onClick={save}
                          >
                            <SaveIcon />
                          </LoadingButton>
                        </Tooltip>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              ) : (
                <>
                  {fArticle && (
                    <>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <FArticlePricesDisplayComponent fArticle={fArticle} />
                        {isAdmin && (
                          <>
                            <IconButton onClick={handleThisEdit}>
                              <EditIcon />
                            </IconButton>
                          </>
                        )}
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          )}
          <Box>
            <Typography
              variant="caption"
              sx={{ color: grey[500], display: "block" }}
            >
              {/*droit copie privée*/}
              {fArticle?.priceDto?.taxe2TaIntitule}
            </Typography>
            <Typography
              variant="caption"
              sx={{ color: grey[500], display: "block" }}
            >
              {/*eco contribution*/}
              {fArticle?.priceDto?.taxe3TaIntitule}
            </Typography>
          </Box>
        </>
      );
    }
  )
);

export default FArticlePricesComponent;
